import { FC } from "react";

const NotFoundPage: FC = () => {
  return (
    <div className="text-center mt-[20vh] max-w-3xl mx-auto text-gray-700">
      <h1 className="text-[5rem] font-bold mb-8">404</h1>
      <p className="text-[1.5rem] font-bold mb-8">Oops! Page not found.</p>
      <p className="text-[1.2rem] mb-3 ">
        If you were trying to reset your password, please make sure you have the
        correct URL. You can retry the link you received by email or try
        navigating back to the password reset page and start the process again.
      </p>
      <p className="text-[1.2rem] font-bold">
        If the issue persists, feel free to contact our support team for
        assistance.
      </p>
      <a
        href="https://www.tmrrwtech.com/feedback"
        className="btn btn-primary mt-8"
      >
        Contact Support
      </a>
    </div>
  );
};

export default NotFoundPage;
