import { sites } from "./sites";

const getSite = (name: string) => {
  const site = sites[name];
  if (!site) {
    throw new Error(`Site ${name} not found`);
  }
  return site;
};

export default getSite;
