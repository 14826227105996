import "./App.css";
import PasswordReset from "./PasswordReset";

function App() {
  return (
  <PasswordReset />
  );
}

export default App;
