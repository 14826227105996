export const sites: Sites = {
  lambton: {
    resetUrl: "https://api.welcomeplatform.com/api/auth/reset-password",
    banner: "./assets/images/welcomebanner.png",
  },
  kingston: {
    resetUrl: "https://kingston.welcomeplatform.com/api/auth/reset-password",
    banner: "./assets/images/kingstonbanner.png",
  },
};
