import { useState } from "react";
import { useForm } from "react-hook-form";
import getSite from "./utils/site/getSite";
import NotFoundPage from "./NotFoundPage";

type Props = {};

const PasswordReset = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const queryParams = new URLSearchParams(window.location.search);
  let path = window.location.pathname;

  let redirect = false;
  let site: Site | undefined = undefined;

  try {
    // remove symbols from path
    path = path.replace(/[^a-zA-Z0-9]/g, "");
    site = getSite(path);
  } catch (error) {
    console.log(error);
  }

  const code = queryParams.get("code");

  if (!code || !site) {
    redirect = true;
  }

  const [error, setError] = useState<string>();
  const [success, setIsSuccess] = useState<boolean>();

  const onSubmit = async (data: any) => {
    await fetch(`${site!.resetUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          setError(data.error.message);
          reset();
        });
      } else if (res.ok) {
        setIsSuccess(true);
        reset();
        return res.json();
      }
    });
  };

  if (redirect) {
    return <NotFoundPage />;
  }

  return (
    <div className="hero min-h-screen">
      <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <figure>
          <img src={require(`${site!.banner}`)} alt="Welcome Banner" />
        </figure>
        <div className="text-center mt-4">
          <h3 className="text-black">Forgot Your Password?</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
              <label className="label">
                <span className="label-text text-black">New Password</span>
              </label>
              <input
                type="password"
                placeholder="Password"
                className="input input-bordered"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                })}
              />

              {errors.password && (
                <p className="text-red-600 mt-2">
                  Password must contain at least: 1 number, 1 uppercase letter,
                  1 lowercase letter and be at least 8 character long{" "}
                </p>
              )}
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text text-black">
                  Confirm New Password
                </span>
              </label>
              <input
                type="password"
                placeholder="Confirm New Password"
                className="input input-bordered"
                {...register("passwordConfirmation", {
                  validate: {
                    matches: (value: any) => {
                      const { password } = getValues();
                      return password === value || "Passwords should match!";
                    },
                  },
                })}
              />
              {errors.passwordConfirmation && (
                <p className="text-red-600 mt-2">Passwords should match!</p>
              )}
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Change Password
              </button>
            </div>
            <div>
              {success && (
                <p className="text-emerald-400  mt-2">Password Changed!</p>
              )}
              {error && <p className="text-red-600  mt-2">{error}</p>}
            </div>
            <input type="hidden" value={code!} {...register("code")} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
